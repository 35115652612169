/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useFrame } from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    macBook_TopPart_Cube004_1: THREE.Mesh;
    macBook_TopPart_Cube004_2: THREE.Mesh;
    macBook_TopPart_Cube004_3: THREE.Mesh;
    macBook_TopPart_Cube004_4: THREE.Mesh;
    macBook_TopPart_Cube004_5: THREE.Mesh;
    macBook_TopPart_Cube004_6: THREE.Mesh;
    macBook_TopPart_Cube004_7: THREE.Mesh;
    macBook_TopPart_Cube004_8: THREE.Mesh;
    macBook_TopPart_Cube004_9: THREE.Mesh;
    macBook_TopPart_Cube004_10: THREE.Mesh;
    macBook_TopPart_Cube004_11: THREE.Mesh;
    macBook_TopPart_Cube004_12: THREE.Mesh;
    macBook_TopPart_Cube004_13: THREE.Mesh;
    macBook_TopPart_Cube004_14: THREE.Mesh;
    macBook_TopPart_Cube004_15: THREE.Mesh;
  };
  materials: {
    Outline: THREE.MeshStandardMaterial;
    Screen: THREE.MeshStandardMaterial;
    Emission: THREE.MeshStandardMaterial;
    Logo: THREE.MeshStandardMaterial;
    Main: THREE.MeshStandardMaterial;
    Text: THREE.MeshStandardMaterial;
    Camera: THREE.MeshStandardMaterial;
    Camera1: THREE.MeshStandardMaterial;
    CameraGreen: THREE.MeshStandardMaterial;
    Black: THREE.MeshStandardMaterial;
    Second: THREE.MeshStandardMaterial;
    KeysMain: THREE.MeshStandardMaterial;
    KeysBottom: THREE.MeshStandardMaterial;
    TopLine: THREE.MeshStandardMaterial;
    DarkGrey: THREE.MeshStandardMaterial;
  };
};

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF(
    "/assets/models/macbook.glb"
  ) as GLTFResult;
  useFrame(({ clock }) => {
    if (group) group.current!.rotation.y = clock.getElapsedTime();
  });
  return (
    <group ref={group} {...props} dispose={null} scale={1}>
      <group rotation={[Math.PI / 1.7, 0, Math.PI]}>
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_1.geometry}
          material={materials.Outline}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_2.geometry}
          material={materials.Screen}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_3.geometry}
          material={materials.Emission}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_4.geometry}
          material={materials.Logo}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_5.geometry}
          material={materials.Main}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_6.geometry}
          material={materials.Text}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_7.geometry}
          material={materials.Camera}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_8.geometry}
          material={materials.Camera1}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_9.geometry}
          material={materials.CameraGreen}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_10.geometry}
          material={materials.Black}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_11.geometry}
          material={materials.Second}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_12.geometry}
          material={materials.KeysMain}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_13.geometry}
          material={materials.KeysBottom}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_14.geometry}
          material={materials.TopLine}
        />
        <mesh
          geometry={nodes.macBook_TopPart_Cube004_15.geometry}
          material={materials.DarkGrey}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/macbook.glb");
